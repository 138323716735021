@tailwind base;
@tailwind components;
@tailwind utilities;



:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: white;
  overflow-x: hidden !important;

}

.montserrat-font {
  font-family: 'Montserrat' !important;
}


.border1{
  background-color: white;
    border-color: #E77800 !important;
    border-width: 1.5px !important;
    min-height: 40px !important;
    border-radius: 5px !important;
    width: 70%;

   /* align-content: center;
    display: flex;
    align-items: center;*/
}



.css-2blzht,.css-1bki5fo{
  background-color: rgb(255, 255, 255) !important;
  border-color: #d89650 !important;
  border-width: 1.5px !important;
  border-radius: 5px !important;

}

.initialFilters-btn{
  font-family: 'Heebo', sans-serif;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 40px;
  font-size: 18px;
  font-weight: 400;

}
.initialFilters-btn:hover{
  background-color:#ffe7cd !important ;
  color: rgb(111, 111, 111);
}

/* Contact us component

.contact-responsive{

}*/

.btn-visit{
  width: 330px;
  border-radius: 10px !important;
  font-weight:400 !important;
  font-size: 40px !important;

  background-color: #E77800 !important;
margin: 10px 0px !important;
padding: 30px 0px !important;
text-shadow: 4px 4px 4px rgba(128, 128, 128, 0.3);
box-shadow: 4px 4px 4px rgba(128, 128, 128, 0.3);
  color:white;

}

@media screen and (max-width: 768px) {
  .btn-visit{
    width: 180px;
    border-radius: 10px !important;
    font-weight:400 !important;
    font-size: 22px !important;
  
    background-color: #E77800 !important;
  margin: 10px 10px !important;
  padding: 30px 0px !important;
  text-shadow: 4px 4px 4px rgba(128, 128, 128, 0.3);
  box-shadow: 4px 4px 4px rgba(128, 128, 128, 0.3);
    color:white;
  
  }
}

@media screen and (max-width: 768px) {
  .btn-visit{
    width: 180px;
    border-radius: 10px !important;
    font-weight:400 !important;
    font-size: 22px !important;
  
    background-color: #E77800 !important;
  margin: 10px 10px !important;
  padding: 30px 0px !important;
  text-shadow: 4px 4px 4px rgba(128, 128, 128, 0.3);
  box-shadow: 4px 4px 4px rgba(128, 128, 128, 0.3);
    color:white;
  
  }
}

@media screen and (max-width: 450px){
  .btn-visit{
    width: 150px;
    border-radius: 10px !important;
    font-weight:400 !important;
    font-size: 18px !important;
  
    background-color: #E77800 !important;
  margin: 5px !important;
  padding: 20px 0px !important;
  text-shadow: 4px 4px 4px rgba(128, 128, 128, 0.3);
  box-shadow: 4px 4px 4px rgba(128, 128, 128, 0.3);
    color:white;
  
  }
}

.not-selected{
  background-color: rgb(202, 198, 198);
  color: rgb(49, 49, 49);
  }
  
  .selected{
    background-color: #f8b46b !important;
    color: rgb(49, 49, 49)
    
  }

  .css-13cymwt-control {
    height: 40px !important;
    overflow: hidden !important;
    position: relative;
    background-color: white;
    border-color: #E77800 !important;
    border-width: 1.5px !important;
    min-height: 40px !important;
}

.css-1dyz3mf{
  display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
 
    max-width: calc(100% - 70px);
    height: 100%; 
    /*overflow-y: auto; /* scroll vertical */
    padding: 4px; 
    box-sizing: border-box;
}
.css-3w2yfm-ValueContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
 
    max-width: calc(100% - 70px);
    height: 100%; 
    /*overflow-y: auto; /* scroll vertical */
    padding: 4px; 
    box-sizing: border-box;
}

.css-1h5i06[data-checked]{
  background-color: #E77800;
  border-color: #E77800;
}


.css-1wy0on6{
  
  height: 38px !important;
  position: absolute !important;
    top: 0;
    right: 0;
}


.css-1hb7zxy-IndicatorsContainer {

  height: 38px !important;
    position: absolute !important;
    top: 0;
    right: 0;
}

.css-1u9des2-indicatorSeparator {
    height: 20px !important;
}

.css-1p3m7a8-multiValue {
    display: flex;
    min-width: 0;
    max-width: 100%;
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    margin: 2px; 
    box-sizing: border-box;
}

.css-wsp0cs-MultiValueGeneric {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    padding: 3px;
    padding-left: 6px;
    box-sizing: border-box;
}

  .css-1nmdiq5-menu{
    color:black!important;
  }


  .sweetalert-index{
    z-index: 10001 !important;
  }

  .leaflet-container a.leaflet-popup-close-button{
    position:absolute;
    top:0rem !important;
    right:0px !important;
  z-index:1000;
  color:white;
  }

  .leaflet-popup-close-button span{
    font-size: 1.3rem;
    font-weight: bold;
    background-color:white;
    border-radius:50px;
    box-shadow: 5px 10px #888888;
padding:3px 8px;
padding-bottom:6px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    color:#E77800 !important;
  }

  
  .leaflet-popup-content-wrapper{
    z-index: 900;
  }


  .leaflet-popup-tip {
    width: 17px;
    right:50%;
    top:98%;
    height: 17px;
    padding: 1px;
    position:absolute;
    z-index:20;
    bottom:-10;
    margin: -10px auto 0;
    pointer-events: auto;
  
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    }



    
  
  .css-1apbzyn{
  
    border-color: #E77800 ;
  }


 


  .react-multi-carousel-item {
      width:auto !important;
  }


/*Collapsable description*/
  

.collapsible {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.collapsed {
  -webkit-line-clamp: 4;
}

.expanded {
  -webkit-line-clamp: unset;
}

